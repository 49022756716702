
import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import GetStarted from "../../components/info-site/GetStarted"
import CaseStudyShark from "../../components/info-site/CaseStudyShark";

const SecretSales = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="sharkCaseTitle" descriptionId="sharkCaseSubTitle" />
            <CaseStudyShark
              title="sharkCaseTitle"
              subTitle="sharkCaseSimpleSubTitle"
            />

            <GetStarted light />
        </Layout>
    );
};

export default SecretSales;